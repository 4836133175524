import { useState } from "react";

import "./App.css";
import pinkLogo from "./_assets/images/pinkcat-pink.png";
import blueLogo from "./_assets/images/pinkcat-blue.png";
import purpleLogo from "./_assets/images/purplecat.png";

function App() {
  const [logo, setLogo] = useState(pinkLogo);
  const handleLogoChange = (logo) => {
    setLogo(logo);
  };
  return (
    <div className="App">
      <header className="App-header">
        <a
          onClick={() => handleLogoChange(purpleLogo)}
          className="link-btn-1 circle-btn circle-btn-color-1"
        ></a>
        <a
          onClick={() => handleLogoChange(blueLogo)}
          className="link-btn-2 circle-btn circle-btn-color-2"
        ></a>
        <a
          onClick={() => handleLogoChange(pinkLogo)}
          className="link-btn-3 circle-btn circle-btn-color-3"
        ></a>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
